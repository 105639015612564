<template>
  <div>
    <v-card
      flat
      class="pt-4"
    >
      <v-simple-table dense class="pt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center text-uppercase">
              Year Of
            </th>
            <th class="text-center text-uppercase">
              Amount
            </th>
            <th class="text-center text-uppercase">
              Invoice Date
            </th>
            <th class="text-center text-uppercase">
              Invoice Number
            </th>
            <th class="text-center text-uppercase">
              Status
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in data"
            :key="item.id"
          >
            <td class="text-center">
              {{ item.year_of }}
            </td>
            <td class="text-center">
              {{ formatPrice(item.amount) }}
            </td>
            <td class="text-center">
              {{ item.or_date }}
            </td>
            <td class="text-center">
              {{ item.or_no }}
            </td>
            <td class="text-center">
              <div v-if="item.deposit_slip_id===null">
                <v-chip small color="error" dark>NOT YET DEPOSITED</v-chip>
              </div>
              <div v-else>
                <v-chip small color="success" dark>DEPOSITED</v-chip>
              </div>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-dialog v-model="view_dialog" max-width="50%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">VIEW SOA</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-3">
          <v-simple-table dense class="pt-2">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-center text-uppercase">
                  ID
                </th>
                <th class="text-center text-uppercase">
                  Date
                </th>
<!--                <th class="text-center text-uppercase">-->
<!--                  Date-->
<!--                </th>-->
<!--                <th class="text-center text-uppercase">-->
<!--                  Time-->
<!--                </th>-->
                <th class="text-center text-uppercase">
                  Amount
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="item in data_soa"
                :key="item.id"
              >
                <td class="text-center">
                  {{ item.deposits.date_of_deposit }}
                </td>
                <td class="text-center">
                  {{ item.id }}
                </td>
<!--                <td class="text-center">-->
<!--                  {{ item.deposits.date_of_deposit }}-->
<!--                </td>-->
<!--                <td class="text-center">-->
<!--                  {{ item.deposits.time_of_deposit }}-->
<!--                </td>-->
                <td class="text-center">
                  {{ formatPrice(item.amount) }}
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>

    </v-dialog>
  </div>
</template>

<script>
  import moment from 'moment'
  import {
    mdiAccountPlus,
    mdiAccountSearchOutline,
    mdiAlertOutline, mdiCash,
    mdiCloudUploadOutline,
    mdiCash100
  } from "@mdi/js";

  export default {
    setup() {
      return {
        view_dialog: false,
        data_soa: [],
        icons: {
          mdiCash100,
          mdiAccountSearchOutline,
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiAccountPlus,
          mdiCash,
        },
      }
    },
    props: {
      data: Array,
    },
    methods: {
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      get_balance(item) {
        var payment = 0;
        item.payments.forEach(function (item) {
          payment += parseFloat(item.amount)
        })
        return (parseFloat(item.amount) - parseFloat(payment))
      },
      view_soa(items) {
        this.view_dialog=true
        this.data_soa = items.payments
      }
    }
  }
</script>
