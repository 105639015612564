<template>
  <div>
    <NewBusiness :key="keyyy" :information_details="data" :for_update="true"
                 v-on:on_update_data="on_update_data"></NewBusiness>
  </div>
</template>

<script>
  import NewBusiness from '@/views/register_interface/NewBusiness'

  export default {
    components: {
      NewBusiness,
    },
    props: {
      keyyy: Number,
      data: Object,
    },
    methods: {
      on_update_data() {

      }
    }
  }
</script>
